import ScrollToTop from '../components/ScrollToTop';
import validate from '../xcl_modules/validate';
import { useEffect  } from 'react';
import { useOutletContext } from "react-router-dom";
import imgSrc from '../css/icons/icon-analytics.png';
import getUserData from '../xcl_modules/userData';

const imgAlt = "Analytics graph icon";

const LRSactivity = () => {

  const [navOpen] = useOutletContext();

  const userData = getUserData();
  const endpoint = userData.xcl_lrs_endpoint;
  const auth = "Basic " + btoa(userData.xcl_lrs_username + ":" + userData.xcl_lrs_password);

  useEffect(() => {
    validate();
  }, []);

  return (
    <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
      <ScrollToTop/>
      <a name="contentstarts" className='contentStarts' href='#start'>
        <h1 name="start">
          <img src={imgSrc} alt={imgAlt} className='iconInline' style={{ verticalAlign: "middle" }} />
          Activity Stream 
        </h1>
      </a>
      <div style={{ marginTop: "16px" }}>
        <iframe title="xAPI Statement Viewer" style={{ width: "100%", borderRadius: "12px", boxShadow: "0px 1px 3px 0px #0000001A", border: "none", height: "2600px" }} src={`https://xclcontent.torontorob.com/statements/?endpoint=${endpoint}&auth=${auth}`}></iframe>
      </div>
    </div>
  );
};

export default LRSactivity;