import XAPI from '@xapi/xapi';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const sOptions = {
    chart: {
        type: 'bar'
    },
    plotOptions: {
        bar: {
            horizontal: true
        }
    },
    fill: {
        colors: ['#0F172A']
    },
    dataLabels: {
        enabled: false
    },
    xaxis: {
        //categories: ["user1", "user2", "user3", "user4", "user5", "user6", "user7", "user8", "user9", "user10"]
        categories: ["user"]
    }
}

const sSeries = [
    {
      name: "series-1",
      //data: [90,87,65,53,52,41,32,15,7,2]
      data: [1]
    }
]

function ChartCompletion(props) {
    const [options, setOptions] = useState( sOptions );
    const [series, setSeries] = useState( sSeries );

    useEffect(() => {
        const endpoint = props.endpoint;
        const auth = XAPI.toBasicAuth(props.username, props.password);
        const xapi = new XAPI({
            endpoint: endpoint,
            auth: auth
        });

        const objectId = props.objectId;

        const updatedOptions = {
            chart: {
                type: 'bar'
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            fill: {
                colors: ['#0F172A']
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: []
            }
        };

        const updatedSeries = [
            {
              name: "total",
              data: []
            }
        ];

        xapi.getStatements({
            activity: objectId            
        }).then((result) => {
            // in promise now

            console.log(result.data);

            // build array per actor
            const actors = [];
            
            result.data.statements.forEach((stmt, i) => {
                actors.push(stmt.actor.mbox);
            });

            // reduce and sort
            const actorCounts = actors.reduce(function (acc, curr) {
                // eslint-disable-next-line
                return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc;
            }, []);
            
            const sortable = [];
            for (var actor in actorCounts) {
                sortable.push([actor, actorCounts[actor]]);
            }
            
            sortable.sort(function(a, b) {
                return b[1] - a[1];
            });

            sortable.forEach((actor, i) => {
                if (i < props.maxlines) {
                    updatedOptions.xaxis.categories.push(actor[0].replace("mailto:",""));
                    updatedSeries[0].data.push(actor[1]);
                }
            })
            
            setOptions(updatedOptions);
            setSeries(updatedSeries);

        });

    },[props]);

    return (
        <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={options}
              series={series}
              type="bar"
              width="400"
            />
          </div>
        </div>
      </div>
    );
}

export default ChartCompletion;
