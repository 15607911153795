import ScrollToTop from '../components/ScrollToTop';
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import validate from '../xcl_modules/validate';
import { useEffect, useState } from 'react';
import getUserData from '../xcl_modules/userData';
import getTokenData from '../xcl_modules/tokenData';
import UpgradeOptions from '../components/UpgradeOptions';
import iconHazard from '../css/icons/icon-hazard.png';
import iconArrowRight from '../css/icons/icon-arrowright-drk.png';
import ConfirmDeleteAccount from '../components/ConfirmDeleteAccount';
import PopupSuccess from '../components/PopupSuccess';

const Account = () => {
  const [role, setRole] = useState(2);
  const [account, setAccount] = useState("");
  const [planName, setPlanName] = useState("");
  const [planEnds, setPlanEnds] = useState("");
  const [isCancelled, setIsCancelled] = useState(false);
  const [subscriptionID, setSubscriptionID] = useState("");
  const [storageAvail, setStorageAvail] = useState(0);
  const [storageUsed, setStorageUsed] = useState(0);
  const [linkLimit, setLinkLimit] = useState(0);
  const [linksUsed, setLinksUsed] = useState(0);
  const [profileLimit, setProfileLimit] = useState(0);
  const [profilesUsed, setProfilesUsed] = useState(0);
  const [portalSession, setPortalSession] = useState("");
  const [monthlyUpgradeURL, setMonthlyURL] = useState("");
  const [yearlyUpgradeURL, setYealryURL] = useState("");
  const [upgradeable, setUpgradeable] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [successPop, setSuccessPop] = useState(false);

  const [navOpen] = useOutletContext();

  const nav = useNavigate();

  useEffect(() => {

    validate();
    const userData = getUserData();

    setRole(userData.role);

    const getData = async () => {
      try {
          
        const response = await fetch(process.env.REACT_APP_BE+`/account/${userData.account}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 
              Authorization: `Bearer ${getTokenData().accessToken}`}
        });

        const result = await response.json();

        if (result.status === "success") {
          setAccount(result.data);
          setPlanName(getPlanName(result.data.plan));
          setLinkLimit(result.data.link_cap);
          setLinksUsed(result.data.links_used);
          setProfileLimit(result.data.link_profile_cap);
          setProfilesUsed(result.data.link_profiles_used);
          setStorageAvail(result.data.storage);
          setStorageUsed(getMBs(result.bytesUsed));
          setSubscriptionID(result.data.cb_subs_id);

          // get sub data here, does not rely on anything else
          //setPlanEnds(started.toDateString());
          const subscriptionData = await fetch(process.env.REACT_APP_BE+`/getsub/${result.data.cb_subs_id}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 
              Authorization: `Bearer ${getTokenData().accessToken}`}
          });
          
          const subscriptionRes = await subscriptionData.json();

          if (subscriptionRes.status === "success") {
            if (typeof(subscriptionRes.subscription.trial_end)!=="undefined") {
              const ends = new Date(subscriptionRes.subscription.trial_end * 1000);
              setPlanEnds(ends.toDateString());
            } else {
              setPlanEnds("never");

            }
            if (typeof(subscriptionRes.subscription.cancelled_at)!=="undefined") {
              setIsCancelled(true);
            }
          }

        }

        const getPortal = async (customerID, subsID) => {
          try {
              
            const response = await fetch(process.env.REACT_APP_BE+`/customerportal`, {
              method: 'POST',
              headers: {'Content-Type': 'application/json', 
                Authorization: `Bearer ${getTokenData().accessToken}`},
              body: JSON.stringify( { cb_cust_id: customerID, cb_subs_id: subsID } )
            });
  
            const result = await response.json();
  
            if (result.status === "success") {
              // we have a portal!  or do we?
              setPortalSession(result.portal);
              setMonthlyURL(result.montly_upgrade.url);
              setYealryURL(result.yearl_upgrade.url);
            }
    
          } catch {
            console.log("error getting portal data - see tools");
          }
        }
    
        getPortal(result.data.cb_cust_id, result.data.cb_subs_id);

      } catch {
        console.log("error getting data - see tools");
      }
    }

    getData();

    

    const getPlanName = (planID) => {
      let plan = "";
      switch (planID) {
        case "ADMIN":
          setUpgradeable(true);
          plan = "System Admin";
          break;
        case "Trial-USD-Weekly":
          setUpgradeable(true);
          plan = "14 Day Trial";
          break;
        case "Basic-USD-Monthly":
          setUpgradeable(true);
          plan = "Build - Monthly";
          break;
        case "Basic-USD-Yearly":
          setUpgradeable(true);
          plan = "Build - Yearly";
          break;
        case "Build-Pro-USD-Monthly":
            plan = "Build Pro - Monthly";
            break;
        case "Build-Pro-USD-Yearly":
            plan = "Build Pro - Yearly";
            break;
        default:
          break;
      }
      return plan;
    }

    const getMBs = (bytes) => {
      return (parseInt(bytes) / 1048576);
    }

  }, []);

  const optionsPopupOpen = () => {
    setPopupOpen(true);
  }

  const optionsPopupClose = () => {
    setPopupOpen(false);
  }

  const confirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
  }

  const deleteAccountConfirm = () => {
    setConfirmDeleteOpen(true);
  }

  const doDeleteAccount = async () => {
    // do delete account fetch
    //console.log("delete it");
    // use cb_subs_id
    try {
      
      const response = await fetch(process.env.REACT_APP_BE+`/cancelsubscription`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 
          Authorization: `Bearer ${getTokenData().accessToken}`},
        body: JSON.stringify( { cb_subs_id: subscriptionID } )
      });

      const result = await response.json();

      if (result.status === "success") {
        console.log(result.message);
        //console.log("deleted!");
        setSuccessPop(true);
        setConfirmDeleteOpen(false);
      }

    } catch {
        console.log("error getting data - see tools");
    }
    

  }
  const closeSuccessPop = () => {
    setSuccessPop(false);
    nav("/logout");
  }


  return (
    <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
      <ScrollToTop/>
      <a name="contentstarts" className='contentStarts' href='#start'>
        <h1 name="start">My Account</h1>
      </a>
      <div className='accountsContainer'>
        <div className='accountBlock' style={ (role===1) ? { width: "100%" } : { width: "70%" } }>
          <h4>My Plan</h4>
          <Link className='acUpgrade' onClick={optionsPopupOpen} style={ (upgradeable) ? ((role===1) ? {  } : { display:"inline-block" } ) : { display: "none" }}>Upgrade Plan</Link>
          <UpgradeOptions 
            open={popupOpen}
            number={0} 
            monthlyURL={monthlyUpgradeURL}
            yearlyURL={yearlyUpgradeURL}
            onClose={optionsPopupClose} />
          <div className='accountDashBlock' style={ (upgradeable) ? ((role===1) ? { marginTop: "30px" } : { }) : { marginTop: "30px" } }>
            <h5>Subscription</h5>
            <div className='accountDashContent'>
              <strong>{planName}</strong>: { (account.sub_status==="in_trial") ? (
                <>
                {(isCancelled) ? (
                  <div style={{color:"red"}}>Cancelled, will be removed on {planEnds}</div>
                ) : (
                  <span>In Trial, ends on {planEnds}.</span>
                )}
              </>
            ):( 
            <>
            <span>Status: {(isCancelled) ? (
              <span style={{color:"red"}}>Cancelled, will be removed on {planEnds}.</span>
            
            ) : ( <>
              <span style={{color:"green"}}>Active</span>
            </> )}</span>
            </>
           )}
            </div>
          </div>

          <div className='accountDashBlock'>
            <h5>Brand Templates</h5>
            <div className='accountDashContent'>
              <div className='accountDashLimit'>{profileLimit} Template{(profileLimit===1) ? '' : 's'}</div>
              <div>
                <div className='acProgressBg'>
                  <div className={ ((profilesUsed/profileLimit>0.75) ? 'acProgress warn' : 'acProgress') } style={ {width: ((profilesUsed/profileLimit)*100)+"%" } }></div>
                </div>
              </div>
              <div className='acLegend'>
                <div className='acLegendPart'>
                  <div className={ ((profilesUsed/profileLimit>0.75) ? 'acProgLegend warn' : 'acProgLegend') }></div>
                  {profilesUsed} Used
                </div>
                <div className='acLegendPart'>
                  <div className='acRemLegend'></div>
                  {profileLimit-profilesUsed} Remaining
                </div>
              </div>
            </div>
          </div>

          <div className='accountDashBlock'>
            <h5>Learning Links</h5>
            <div className='accountDashContent'>
              <div className='accountDashLimit'>{linkLimit} Link{(linkLimit===1) ? '' : 's'}</div>
              <div className='acProgressBg'>
                <div className={ ((linksUsed/linkLimit>0.75) ? 'acProgress warn' : 'acProgress') } style={ {width: ((linksUsed/linkLimit)*100)+"%" } }></div>
              </div>
              <div className='acLegend'>
                <div className='acLegendPart'>
                  <div className={ ((linksUsed/linkLimit>0.75) ? 'acProgLegend warn' : 'acProgLegend') }></div>
                  {linksUsed} Used
                </div>
                <div className='acLegendPart'>
                  <div className='acRemLegend'></div>
                  {linkLimit-linksUsed} Remaining
                </div>
              </div>
            </div>
          </div>

          <div className='accountDashBlock'>
            <h5>Storage</h5>
            <div className='accountDashContent'>
              <div className='accountDashLimit'>{(storageAvail>1000) ? (storageAvail / 1024) + ' GB' : storageAvail + ' MB'}</div>
              <div className='acProgressBg'>
                <div className={ ((storageUsed/storageAvail>0.75) ? 'acProgress warn' : 'acProgress') } style={ {width: ((storageUsed/storageAvail)*100)+"%" } }></div>
              </div>
              <div className='acLegend'>
                <div className='acLegendPart'>
                  <div className={ ((storageUsed/storageAvail>0.75) ? 'acProgLegend warn' : 'acProgLegend') }></div>
                  {(storageUsed>1000) ? Math.round((storageUsed / 1024)) + ' GB' : Math.round(storageUsed) + ' MB'} used
                </div>
                <div className='acLegendPart'>
                  <div className='acRemLegend'></div>
                  {((storageAvail-storageUsed)>1000) ? Math.round(((storageAvail-storageUsed) / 1024)) + ' GB' : Math.round((storageAvail-storageUsed)) + ' MB'} available
                </div>
              </div>
            </div>
          </div>
          <Link to="/logout" className='logoutButton'>Logout</Link>
        </div>

        <div className='accountBlock' style={ (role===1) ? { display: "none" } : {} }>
            <iframe src={portalSession.access_url} style={{ minWidth: "460px", height: "773px", border: "none", borderRadius: "12px" }} loading='eager' title='My Account Portal'></iframe>
        </div>
      </div>

      <div className='accountsContainer' style={ (isCancelled) ? { display:"none" } : { display: "flex" }}>

        <div className='accountBlock' style={{ width: "100%" }}>
          
            <div className='acCalloutBlock delete'>
              <div className='acCalloutIcon' style={{ backgroundImage: `url(${iconHazard})`}}></div>
              <h4>Delete Account</h4>
            
              <p>The following will occur when you delete your account.</p>
              <ul>
                <li>Permanently delete all account information</li>
                <li>Permanently delete all links and branding material</li>
                <li>Your subscription will be cancelled</li>
              </ul>
              <p className='emphasized'>Once you delete your account, there is no going back. Please be certain.</p>
              <Link className='acCalloutBtn delete' style={{ backgroundImage: `url(${iconArrowRight})`}} onClick={deleteAccountConfirm}>Delete My Account</Link>
            </div>

            <ConfirmDeleteAccount 
            open={confirmDeleteOpen}
            subscriptionID={subscriptionID}
            onDelete={doDeleteAccount}
            onClose={confirmDeleteClose} />

            <PopupSuccess 
            open={successPop}
            element="Account"
            onClose={closeSuccessPop} />
        </div>
        
      </div>


    </div>
  );
};

export default Account;