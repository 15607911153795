import ScrollToTop from '../components/ScrollToTop';
import validate from '../xcl_modules/validate';
import { useState, useEffect } from 'react';
import Header from "../components/Header";
import createStyles from '../xcl_modules/createStyles';
import { Link, useNavigate } from "react-router-dom";
import saveStepIcon from '../css/icons/icon-arrowright-drk.png';
import prevStepIcon from '../css/icons/icon-arrowleft.png';
import getTokenData from '../xcl_modules/tokenData';
import DropzoneLogo from '../components/DropzoneLogo';
import LivePreview from '../components/LivePreview';

const startFieldsData = {
    "0": {
        "ID": 26,
        "parent": 132567,
        "fieldID": "firstname",
        "type": "text",
        "label": "First Name",
        "visible": 1,
        "required": 1,
        "ordinal": 0,
        "status": 1
    },
    "1": {
        "ID": 27,
        "parent": 132567,
        "fieldID": "lastname",
        "type": "text",
        "label": "Last Name",
        "visible": 1,
        "required": 1,
        "ordinal": 1,
        "status": 1
    },
    "2": {
        "ID": 28,
        "parent": 132567,
        "fieldID": "email",
        "type": "text",
        "label": "Email Address",
        "visible": 1,
        "required": 1,
        "ordinal": 2,
        "status": 1
    },
    "3": {
        "ID": 29,
        "parent": 132567,
        "fieldID": "userid",
        "type": "text",
        "label": "User ID",
        "visible": 1,
        "required": 0,
        "ordinal": 3,
        "status": 1
    }
  };
  
  const ProfileBranding = () => {

    const [inputs, setInputs] = useState( {} );
    const [systemSettings, setSystemSettings] = useState( {} );
    const [profileFields, setFields] = useState( startFieldsData );
    //const [profileRecipes, setRecipes] = useState( {} );
    const [lpMode, setLpMode] = useState( "web" );

    const navigate = useNavigate();

    useEffect(() => {
        validate();
        // get data
        const getData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_BE+`/linkprofile/${localStorage.getItem("editProfileID")}`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json', 
                        Authorization: `Bearer ${getTokenData().accessToken}`}
                });
                const result = await response.json();

                if (result.status === "success") {
                    const initialInputs = {};
                    const systemSettins = {};
                    Object.entries(result.data[0]).forEach(([key, val]) => {
                        initialInputs[key] = val;
                    });
                    Object.entries(result.fields).forEach(([key, val]) => {
                        setFields(values => ({...values, [key]: val }));
                    });
                    /*Object.entries(result.recipes[0]).forEach(([key, val]) => {
                        setRecipes(values => ({...values, [key]: val }));
                    });*/
                    result.system_settings.forEach((setting) => {
                        systemSettins[setting.appid] = setting.value;
                        setSystemSettings(values => ({...values, [setting.appid]: setting.value }));
                    });
                    // sort if needed
                    Object.entries(systemSettins).forEach(([key, val]) => {
                        if (typeof(initialInputs[key])!=="undefined") {
                            if (initialInputs[key]==="") {
                                initialInputs[key] = val;
                            }
                        }
                    });
                    //console.log(initialInputs);
                    Object.entries(initialInputs).forEach(([key, val]) => {
                        setInputs(values => ({...values, [key]: val }));
                    });
                }

                //console.log(inputs);
            } catch {
                console.log("error getting data - log in again");
            }
        }
        getData();
    }, []);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const saveData = async () => {

            try {
                const response = await fetch(process.env.REACT_APP_BE+'/linkprofile', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', 
                        Authorization: `Bearer ${getTokenData().accessToken}`},
                    body: JSON.stringify( { ID: localStorage.getItem("editProfileID"), brand_bg_color: inputs.brand_bg_color, brand_logo: inputs.brand_logo, brand_logo_alt: inputs.brand_logo_alt } )
                });
                const result = await response.json();

                if (result.status === "success") {
                    console.log("profile updated");
                    navigate("/profilecustomize");
                }
            } catch {
                console.log("error with data - log in again");
            }
        }
        saveData();
    }

    const handlePrevNavigation = (event) => {
        event.preventDefault();
        navigate("/profilelrs");
    }

    const changeModeToMobile = () => { 
        setLpMode( "mobile" );
    }

    const changeModeToWeb = () => { 
        setLpMode( "web" );
    }

    const doColorChange = (event) => {
        const key = "brand_bg_color";
        const val = event.target.value;
        setInputs(values => ({...values, [key]: val}));
    }
  
  
    const progress = "75%";

    return (
        <>
        <Header/>
        <ScrollToTop/>
        <style>{createStyles}</style>
        <div className='createnew header'>
            <div className='stepperheader'>
            <Link to="/xcl/profiles">&lt; Back to Dashboard</Link>
                <h3>
                Branded Landing Page Template 
                </h3>
                <p>
                You'll be able to apply this template to your learning link landing pages. 
                </p>
                <div className='steps'>
                <div className='step prior'>
                    <Link to="/profilename">
                    <div className='indicator' title="step 1">&#10003;</div>
                    Template Name
                    </Link>
                </div>
                <hr/>
                <div className='step prior'>
                    <Link to="/profilelrs">
                    <div className='indicator' title="step 2">&#10003;</div>
                    LRS Details
                    </Link>
                </div>
                <hr/>
                <div className='step active'>
                    <Link>
                    <div className='indicator' title="step 3">3</div>
                    Logo & Background Color
                    </Link>
                </div>
                <hr/>
                <div className='step upcoming'>
                    <Link to="/profilecustomize">
                    <div className='indicator' title="step 4">4</div>
                    Customize Fields
                    </Link>
                </div>
                </div>
            </div>
        </div>
        <form onSubmit={handleSubmit}>
            <div className='formContainer'>
                <a name="contentstarts" className='contentStarts' href='#start'>
                    <h4 name="start">Landing Page Preview</h4>
                </a>
                <div className='lpControls'>
                    <input type="button" className={(lpMode === "mobile") ? "active" : ""} value="Mobile" onClick={changeModeToMobile}/>
                    <input type="button" className={(lpMode === "web") ? "active" : ""} value="Web" onClick={changeModeToWeb}/>
                </div>
                <LivePreview
                lpMode={lpMode}
                profileFields={profileFields}
                inputs={inputs}
                systemSettings={systemSettings}
                />
            </div>

            <div className='formContainer'>
                <h4>Add your logo and apply a background color</h4>
                <label htmlFor="brand_bg_color">Select a Background Color</label>
            <h5>Click on the color to open the color picker, or enter a hex code.</h5>
            <input type="color"
            maxLength="7"
              value={inputs.brand_bg_color || "" }
              name="brand_bg_color"
              onChange={handleChange}/>
            <input type="text"
            maxLength="7"
              className='smallInput'
              style={{marginLeft: "24px", position: "relative", top: "-8px", width: "width: 68px !important"}}
              value={inputs.brand_bg_color || ""}
              onChange={doColorChange}
              name="show_color"
               />
            <hr />
            {
              inputs.brand_logo ? (
                <>
                  <DropzoneLogo 
                    brand_logo={inputs.brand_logo} 
                    onChange={(name, value) => setInputs(values => ({...values, [name]: value})) } 
                    profileID={localStorage.getItem("editProfileID")}
                    storage={inputs.storage}
                    storageAvailable={inputs.storageAvailable}
                    />
                  <div className="fileName">File Name: <strong>{inputs.brand_logo || ""}</strong></div>
                </>
                
              ) : null
            }
            
            <input type="hidden"
                value={inputs.brand_logo || ""}
                name="brand_logo"
                onChange={handleChange}
                ></input>
            <label htmlFor="brand_logo_alt">Logo Alternative Text</label>
            <input type="text"
                value={inputs.brand_logo_alt || ""}
                name="brand_logo_alt" 
                maxLength="60"
                onChange={handleChange}
                placeholder="XCL"></input>
            </div>

            <div className='formFooter'>
                <div className='progressbarbackground'>
                    <div className='progressbarprogress' style={{ width: progress }}></div>
                </div>
                <div className='stepperFormControls'>
                    <input type="button" name='backone' value="Previous: LRS Details" style={{ backgroundImage: `url('${prevStepIcon}')`}} onClick={handlePrevNavigation}></input>
                    <input type="submit" name='subitBtn' value="Next: Customize Fields " style={{ backgroundImage: `url('${saveStepIcon}')`}}></input>
                </div>
            </div>
      </form>
      <div className='end'> </div>
    </>
  );
};

export default ProfileBranding;