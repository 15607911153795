import ScrollToTop from '../components/ScrollToTop';
import validate from '../xcl_modules/validate';
import { useState, useEffect } from 'react';
import Header from "../components/Header";
import createStyles from '../xcl_modules/createStyles';
import { Link, useNavigate } from "react-router-dom";
import saveStepIcon from '../css/icons/icon-arrowright-drk.png';
import prevStepIcon from '../css/icons/icon-arrowleft.png';
import getTokenData from '../xcl_modules/tokenData';
import iconCongrats from '../css/icons/icon-congrats-purple.png';
import ConfettiExplosion  from 'react-confetti-explosion';
import Swal from 'sweetalert2';

const iconCongratsAlt = "Congratulations icon";

const largeProps = {
    force: 0.8,
    duration: 3000,
    particleCount: 300,
    particleSize: 22,
    width: 1600,
    colors: ['#00BBF9', '#9B5DE5', '#00F5D4', '#FEE440', '#F15BB5'],
};

const LinkPublish = () => {

    //const [inputs, setInputs] = useState( { name: "" } );
    const [linkHref, setlinkHref] = useState( "" );
    const [copyMsg, setCopyMsg] = useState( "Copy Link" );
    const [published, setPublished] = useState( 0 );

    const [isLargeExploding, setIsLargeExploding] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        validate();
        // get data
        // local storage key is "editLinkID"
        const getData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_BE+`/link/${localStorage.getItem("editLinkID")}`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json', 
                        Authorization: `Bearer ${getTokenData().accessToken}`}
                });

                const result = await response.json();

                if (result.status === "success") {

                    // build the link
                    setlinkHref(`${process.env.REACT_APP_LINK_HOST}/?n=${result.data[0].linkID}`);

                    // set the published status
                    if (result.data[0].published === 0) {
                        setIsLargeExploding(true);
                        setPublished( 1 );
                        try {
                            await fetch(process.env.REACT_APP_BE+`/link`, {
                                method: 'POST',
                                headers: {'Content-Type': 'application/json', 
                                    Authorization: `Bearer ${getTokenData().accessToken}`},
                                body: JSON.stringify( { ID: localStorage.getItem("editLinkID"), published: 1 } )
                            });
                        } catch {
                            Swal.fire({
                                position: "top",
                                iconHtml:  '<div class="positoast"></div>',
                                title: "System Error :O  Please Login again.",
                                showConfirmButton: false,
                                timer: 1500,
                                toast: true,
                                timerProgressBar: true,
                                showClass: {
                                    popup: 'toastCongratsOn'
                                },
                                hideClass: {
                                    popup: 'toastCongratsOff'
                                }
                            });
                        }
                    }
        
                }
                
            } catch {
                Swal.fire({
                    position: "top",
                    iconHtml:  '<div class="positoast"></div>',
                    title: "System Error :O  Please Login again.",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                    timerProgressBar: true,
                    showClass: {
                        popup: 'toastCongratsOn'
                    },
                    hideClass: {
                        popup: 'toastCongratsOff'
                    }
                });
            }
        }

        getData();
    }, []);

    const handlePrevNavigation = (event) => {
        event.preventDefault();
        navigate("/linkcontent");
    }
  
    const handleSubmit = (event) => {
        event.preventDefault();
        navigate("/xcl/links");
    }

    const progress = "100%";

    const doLinkCopy = () => {
        navigator.clipboard.writeText(linkHref.toString()).then(() => {
            setCopyMsg("Copied!");
        }).catch(() => {
            setCopyMsg("Try again");
        });
    }
    
    
    
    return (
        <>
        <Header/>
        <ScrollToTop/>
        <style>{createStyles}</style>
        <div className='createnew header'>
            <div className='stepperheader'>
                <Link to="/xcl/links">&lt; Back to Dashboard</Link>
                <h3>
                Learning Link 
                </h3>
                <p>
                Customize your learning link and easily apply a brand template. 
                </p>
                <div className='steps'>
                  <div className='step prior'>
                      <Link to="/linkname">
                      <div className='indicator' title="step 1">&#10003;</div>
                      Name and Brand Details
                      </Link>
                  </div>
                  <hr/>
                  <div className='step prior'>
                      <Link to="/linkcontent">
                      <div className='indicator' title="step 2">&#10003;</div>
                      Learning Link Content
                      </Link>
                  </div>
                  <hr/>
                  <div className='step links active'>
                      <Link>
                      <div className='indicator' title="step 3">3</div>
                      Publish Link
                      </Link>
                  </div>
                </div>
            </div>
        </div>
        <form onSubmit={handleSubmit}>

          <div className='formContainer'>
            <img src={iconCongrats} alt={iconCongratsAlt} style={{ display: "block", width: "60px", margin: "0px auto" }} />
            <div style={{ display: "block", height: "1px", width: "1px", margin: "0px auto" }} published={published}>
                {isLargeExploding && <ConfettiExplosion {...largeProps} />}
            </div>
            <a name="contentstarts" className='contentStarts' href='#start' style={{ margin: "0px auto", display: "block", width: "200px" }}>
                <h4 style={{ textAlign: "center" }}>Congratulations!</h4>
            </a>
            <h5 style={{ textAlign: "center" }}>Your learning link has been published and is now live online.</h5>
            
            <input type="text" name="linktext" disabled value={linkHref} style={{ width: "calc(100% - 180px)", marginRight: "16px", height: "48px" }} />
            <input type="button" value={copyMsg} onClick={doLinkCopy} name="copybtn" style={{ height: "48px", fontSize: "1.125rem" }} ></input>

            <Link className='linkpublish' to={linkHref} target='_blank'>Open Learning Link</Link>
            
          </div>
          
        

        <div className='formFooter'>
            <div className='progressbarbackground'>
                <div className='progressbarprogress links' style={{ width: progress }}></div>
            </div>
            <div className='stepperFormControls'>
                <input type="button" name='backone' value="Previous: Link Content" style={{ backgroundImage: `url('${prevStepIcon}')`}} onClick={handlePrevNavigation}></input>
                <input type="submit" name='subitBtn' value="Exit" style={{ backgroundImage: `url('${saveStepIcon}')`}}></input>
            </div>
        </div>
      </form>
      <div className='end'> </div>
    </>
  );
};

export default LinkPublish;