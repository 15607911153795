import ScrollToTop from '../components/ScrollToTop';
import validate from '../xcl_modules/validate';
import { useState, useEffect  } from 'react';
import { useOutletContext } from "react-router-dom";
import imgSrc from '../css/icons/icon-analytics.png';
import getTokenData from '../xcl_modules/tokenData';
import Swal from 'sweetalert2';
import ChartCompletion from '../components/ChartCompletion';
import ChartTopUsers from '../components/ChartTopUsers';

const imgAlt = "Analytics graph icon";

const LRSdashboard = () => {

  const [hookedData, setData] = useState( [] );
  const [navOpen] = useOutletContext();
  
  useEffect(() => {
    validate();

    const getData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BE+'/linklist', {
          method: 'GET',
          headers: {'Content-Type': 'application/json', 
              Authorization: `Bearer ${getTokenData().accessToken}`}
        });
        const result = await response.json();
        if (result.code === 202) {
          // there are no links :(
          result.list = [ { ID:0, listEmpty: "message" } ];
        } else {
        }
        //setTemplates(result.profiles.length);
        setData(result.list);
      } catch {
        Swal.fire({
          position: "top",
          iconHtml:  '<div class="positoast"></div>',
          title: "System Error :O  Please Login again.",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
          showClass: {
            popup: 'toastCongratsOn'
          },
          hideClass: {
            popup: 'toastCongratsOff'
          }
        });
      }
    }
    getData();
  }, []);

  return (
    <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
      <ScrollToTop/>
      <a name="contentstarts" className='contentStarts' href='#start'>
        <h1 name="start">
          <img src={imgSrc} alt={imgAlt} className='iconInline' style={{ verticalAlign: "middle" }} />
          My Analytics Dashboard 
        </h1>
      </a>

      <div style={{ marginTop: "26px", display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "24px", justifyContent: "center" }}>
        {hookedData.map((linkData, i) => 
          <div key={"chart" + i} style={{ border:"1px solid #343434", borderRadius: "12px", padding: "16px" }}>
            <h5>{linkData.name}</h5>
            <p>Launch vs Completion</p>
            <ChartCompletion
              endpoint={(linkData.xcl_lrs===1) ? linkData.xcl_lrs_endpoint : linkData.lrs_endpoint}
              username={(linkData.xcl_lrs===1) ? linkData.xcl_lrs_username : linkData.lrs_username}
              password={(linkData.xcl_lrs===1) ? linkData.xcl_lrs_password : linkData.lrs_password}
              type={linkData.launch_link_type}
              objectId={linkData.launch_link}
            />
            <p>Top Users</p>
            <ChartTopUsers
              endpoint={(linkData.xcl_lrs===1) ? linkData.xcl_lrs_endpoint : linkData.lrs_endpoint}
              username={(linkData.xcl_lrs===1) ? linkData.xcl_lrs_username : linkData.lrs_username}
              password={(linkData.xcl_lrs===1) ? linkData.xcl_lrs_password : linkData.lrs_password}
              objectId={linkData.launch_link}
              maxlines="10"
            />
          </div>
        ) }
      </div>

    </div>
  );
};

export default LRSdashboard;