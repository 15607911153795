import XAPI from '@xapi/xapi';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const sOptions = {
    chart: {
        id: "basic-bar"
    },
    xaxis: {
        categories: ["launched"]
    },
    fill: {
        colors: ['#6ca7ad', '#9f7fc9']
    },
    dataLabels: {
        enabled: true,
        style: {
          colors: ["#000"]
        }
    }
}

const sSeries = [
    {
      name: "total",
      data: [0]
    }
]

function ChartCompletion(props) {
    const [options, setOptions] = useState( sOptions );
    const [series, setSeries] = useState( sSeries );

    useEffect(() => {
        const endpoint = props.endpoint;
        const auth = XAPI.toBasicAuth(props.username, props.password);
        const xapi = new XAPI({
            endpoint: endpoint,
            auth: auth
        });

        const objectId = props.objectId;

        const startVerb = (props.type==="XAPI" || props.type==="SCORM")? "http://adlnet.gov/expapi/verbs/attempted" : "http://adlnet.gov/expapi/verbs/launched";
        const completedVerb = (props.type==="XAPI" || props.type==="SCORM")? "http://adlnet.gov/expapi/verbs/completed" : "http://activitystrea.ms/schema/1.0/complete";
        
        const cats = (props.type==="XAPI" || props.type==="SCORM" || props.type==="VIDEO") ? ["launched", "completed"] : ["launched"];
        // update
        const updatedOptions = {
            chart: {
                id: "basic-bar"
            },
            xaxis: {
                categories: cats
            },
            fill: {
                colors: ['#6ca7ad', '#9f7fc9']
            },
            dataLabels: {
                enabled: true,
                style: {
                  colors: ["#000"]
                }
            }
        }
        setOptions(updatedOptions);

        const seriesData = [];

        // start statements
        xapi.getStatements({
            activity: objectId,
            verb: startVerb            
        }).then((result) => {

            seriesData[0] = result.data.statements.length;

            // completed statements (if needed)
            if (props.type==="XAPI" || props.type==="SCORM" || props.type==="VIDEO") {
                xapi.getStatements({
                    activity: objectId,
                    verb: completedVerb            
                }).then((result) => {
                    seriesData[1]  = result.data.statements.length;

                    const updatedSeries = [
                        {
                            name: "total",
                            data: seriesData
                        }
                    ];
                    setSeries(updatedSeries);
    
                });
            } else {
                const updatedSeries = [
                    {
                        name: "total",
                        data: seriesData,
                    }
                ];
                setSeries(updatedSeries);
            }

        });

    },[props]);

    return (
        <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={options}
              series={series}
              type="bar"
              width="400"
            />
          </div>
        </div>
      </div>
    );
}

export default ChartCompletion;
