import { useState, useEffect } from 'react';
import validate from '../xcl_modules/validate';
import ScrollToTop from '../components/ScrollToTop';
import adminOnly from '../xcl_modules/adminOnly';
import { getAdminData, saveAdminData } from '../xcl_modules/adminData';
import adminGear from '../css/icons/icon-gear.png';
import DropzoneLogo from '../components/DropzoneLogo';
import { useOutletContext } from "react-router-dom";
import startFieldsData from '../xcl_modules/startFieldsData';
import LivePreview from '../components/LivePreview';

const adminGearAlt = "System Administrator Settings Icon";

const AdminSettings = () => {

  const [inputs, setInputs] = useState( {} );
  const [lpMode, setLpMode] = useState( "web" );

  const [navOpen] = useOutletContext();

  useEffect(() => {
    
    const getData = async () => {
      const adminData = await getAdminData();
        
      adminData.settings.forEach( (element) => {
        //console.log(`element.appid=${element.appid} --- element.value=${element.value}`);
        if (element.value !== null && element.value !== "") {
          const name = element.appid;
          const value = element.value;
          setInputs(values => ({...values, [name]: value}));
        }
      })
      // create a random flag
      const randkey = "isRandom";
      const randVal = 0;
      setInputs(values => ({...values, [randkey]: randVal}));
      
    }
    getData();
    validate();
  }, []);

  if (adminOnly) {

    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}));
    }

    const changeModeToWeb = () => { 
      setLpMode( "web" );
    }

    const changeModeToMobile = () => { 
      setLpMode( "mobile" );
    }

    const doCancel = () => {
      document.location.href = "/xcl/";
    }

    const handleSubmit = async (event) => {
      event.preventDefault();

      const saveRef = await saveAdminData(inputs);
      console.log(saveRef);
    }

    const doColorChange = (event) => {
      const key = "brand_bg_color";
      const val = event.target.value;
      setInputs(values => ({...values, [key]: val}));
    }

    return (
      <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
        <ScrollToTop/>
        <a name="contentstarts" className='contentStarts' href='#start'>
          <h1 name="start">
            <img src={adminGear} alt={adminGearAlt} className='iconInline' />
              Admin Settings
          </h1>
        </a>
        <h4> 
            Manage the XCL Build Capable settings here.
        </h4>
        <form onSubmit={handleSubmit}>
          <div className='pageTile'>
            <h3>Empty Landing Page Redirect</h3>
            <h5>Enter a URL that can redirect people who land on the XCL without a formatted link.</h5>
            <label htmlFor="url_redirect">URL (Optional)</label>
            <input type="text"
                value={inputs.url_redirect || ""} 
                name="url_redirect" 
                maxLength={200}
                onChange={handleChange}
                placeholder="https://www.buildcapable.com/"></input>
          </div>
          <div className='pageTile'>
            <h3>XCL Landing Page Preview</h3>
            <div className='lpControls'>
              <input type="button" className={(lpMode === "mobile") ? "active" : ""} value="Mobile" onClick={changeModeToMobile}/>
              <input type="button" className={(lpMode === "web") ? "active" : ""} value="Web" onClick={changeModeToWeb}/>
            </div>
            <LivePreview
              lpMode={lpMode}
              profileFields={startFieldsData}
              inputs={inputs}
              systemSettings={inputs}
            />

            <h3>XCL Landing Page Branding Defaults</h3>
            <label htmlFor="brand_bg_color">Select a Background Color</label>
            <h5>Click on the color to open the color picker, or enter a hex code.</h5>
            <input type="color"
              value={inputs.brand_bg_color || "#00606B"}
              name="brand_bg_color"
              maxLength={7}
              onChange={handleChange}/>
            <input type="text"
              className='smallInput'
              style={{marginLeft: "24px", position: "relative", top: "-8px", width: "width: 68px !important"}}
              value={inputs.brand_bg_color || ""}
              name="show_color"
              maxLength={7} 
              onChange={doColorChange}
               />
            <hr />
            {
              inputs.brand_logo ? (
                <>
                  <DropzoneLogo brand_logo={inputs.brand_logo} onChange={(name, value) => setInputs(values => ({...values, [name]: value})) } />
                  <div className="fileName">File Name: <strong>{inputs.brand_logo || ""}</strong></div>
                </>
                
              ) : null
            }
            
            <input type="hidden"
                value={inputs.brand_logo || ""}
                name="brand_logo"
                maxLength={200} 
                onChange={handleChange}
                ></input>
            <label htmlFor="brand_logo_alt">Logo Alternative Text</label>
            <input type="text"
                value={inputs.brand_logo_alt || ""} 
                name="brand_logo_alt" 
                maxLength={60} 
                onChange={handleChange}
                placeholder="XCL"></input>
            <label htmlFor="headline">Headline Text</label>
            <input type="text"
                value={inputs.headline || ""} 
                name="headline" 
                maxLength={60} 
                onChange={handleChange}
                placeholder="Welcome to the XCL Training Module"></input>
            <label htmlFor="instructionaltext">Instructional Text  <span className='lightText'>(Optional)</span></label>
            <input type="text"
                value={inputs.instructionaltext || ""} 
                name="instructionaltext" 
                maxLength={150} 
                onChange={handleChange}
                placeholder="Please complete the form to begin"></input>
            <label htmlFor="submitbuttonlabel">Launch Button Text  </label>
            <input type="text"
                value={inputs.submitbuttonlabel || ""} 
                name="submitbuttonlabel" 
                maxLength={45} 
                onChange={handleChange}
                placeholder="Continue"></input>
            <label htmlFor="clearbuttonlabel">Clear Form Button Text</label>
            <input type="text"
                value={inputs.clearbuttonlabel || ""} 
                maxLength={45} 
                name="clearbuttonlabel" 
                onChange={handleChange}
                placeholder="Clear Form"></input>

          </div>
          <div className='pageTile'>
            <h3>Accessibility Settings</h3>
            <h5>Enter how would you like to name anonymous users. If your users are not going to be using any identifiable data, customize how you would you like to label that information.</h5>
            <label htmlFor="large_text_increase">Large Text Increase Size (Represented in em)</label>
            <input type="text"
                value={inputs.large_text_increase || ""} 
                name="large_text_increase" 
                maxLength={10} 
                onChange={handleChange}
                placeholder="1.5em"></input>
            <label htmlFor="mandatory_symbol">Mandatory Field Text</label>
            <input type="text"
                value={inputs.mandatory_symbol || ""} 
                name="mandatory_symbol"
                maxLength={45}  
                onChange={handleChange}
                placeholder="(Required)"></input>
          </div>
          <div className='formControls'>
            <input type="button" name='cancelBtn' value="Cancel" onClick={doCancel}></input>
            <input type="submit" name='subitBtn' value="Save Changes" style={{marginLeft:"24px"}}></input>
          </div>
        </form>
      </div>
    )
  } else {
    document.location.href = "/login";
    return <div>no access</div>
  }
};

export default AdminSettings;