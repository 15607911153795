import ScrollToTop from '../components/ScrollToTop';
import validate from '../xcl_modules/validate';
import { useState, useEffect } from 'react';
import Header from "../components/Header";
import createStyles from '../xcl_modules/createStyles';
import dropdownstyles from '../xcl_modules/dropdownstyle';
import { Link, useNavigate } from "react-router-dom";
import saveStepIcon from '../css/icons/icon-arrowright-drk.png';
import getTokenData from '../xcl_modules/tokenData';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import LivePreview from '../components/LivePreview';
import getLinks from '../xcl_modules/links';
import Swal from 'sweetalert2';

const startFieldsData = {
    "0": {
        "ID": 26,
        "parent": 132567,
        "fieldID": "firstname",
        "type": "text",
        "label": "First Name",
        "visible": 1,
        "required": 1,
        "ordinal": 0,
        "status": 1
    },
    "1": {
        "ID": 27,
        "parent": 132567,
        "fieldID": "lastname",
        "type": "text",
        "label": "Last Name",
        "visible": 1,
        "required": 1,
        "ordinal": 1,
        "status": 1
    },
    "2": {
        "ID": 28,
        "parent": 132567,
        "fieldID": "email",
        "type": "text",
        "label": "Email Address",
        "visible": 1,
        "required": 1,
        "ordinal": 2,
        "status": 1
    },
    "3": {
        "ID": 29,
        "parent": 132567,
        "fieldID": "userid",
        "type": "text",
        "label": "User ID",
        "visible": 1,
        "required": 0,
        "ordinal": 3,
        "status": 1
    }
  };
  
  const LinkNew = () => {

    const [inputs, setInputs] = useState( { name: "" } );
    const [systemSettings, setSystemSettings] = useState( {} );
    const [profileFields, setFields] = useState( startFieldsData );
    const [profiles, setProfiles] = useState( {} );
    const [profileIDs, setProfilesIDs] = useState( [] );
    const [currentProfle, setCurrentProfile] = useState( {} );
    const [lpMode, setLpMode] = useState( "web" );

    const navigate = useNavigate();

    const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(2);

    const links = getLinks();
    const thisLID = localStorage.getItem("editLinkID") ? parseInt(localStorage.getItem("editLinkID")) : 0;
  
    // groom the profiles to remove this one
    if (links.length > 1) {
      let index = 0;
      links.forEach((link, i) => {
        if (link.ID === thisLID) {
          index = i;
        }
      });
      links.splice(index, 1);
    }

    useEffect(() => {
        validate();
        // get data
        // local storage key is "editLinkID"
        const getData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_BE+`/links`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json', 
                        Authorization: `Bearer ${getTokenData().accessToken}`}
                });

                const result = await response.json();

                if (result.status === "success" || result.code === 202) {
                    const initialInputs = {};
                    const systemSettins = {};
                    const profilesBuilder = [];
                    const profilesIDsBuilder = [];
                    if (result.profiles.length < 1) {
                      // hard exit
                      document.location = "/xcl/links";
                    }
                    Object.entries(result.profiles[0]).forEach(([key, val]) => {
                        if (key === "fields") {
                          Object.entries(val).forEach(([key, val]) => {
                            setFields(values => ({...values, [key]: val }));
                          });
                        } else {
                          initialInputs[key] = val;
                        }
                    });
                    Object.entries(result.profiles).forEach(([key, val]) => {
                      profilesBuilder.push(val);
                      profilesIDsBuilder.push(val.ID);
                    });
                    setCurrentProfile({ name: profilesBuilder[0].name, ID: profilesBuilder[0].ID });

                    setProfiles(profilesBuilder);
                    setProfilesIDs(profilesIDsBuilder);

                    result.system_settings.forEach((setting) => {
                        systemSettins[setting.appid] = setting.value;
                        setSystemSettings(values => ({...values, [setting.appid]: setting.value }));
                    });
                    // sort if needed
                    const accpetedOverwrites = ['headline', 'instructionaltext', 'submitbuttonlabel', 'browsertabtitle'];
                    accpetedOverwrites.forEach((key) => {
                      initialInputs[key] = "";
                    })
                    Object.entries(systemSettins).forEach(([key, val]) => {
                            if (accpetedOverwrites.includes(key)) {
                              initialInputs[key] = val;
                            }
                    });
                    
                    Object.entries(initialInputs).forEach(([key, val]) => {
                      if (key!=="name") {
                        setInputs(values => ({...values, [key]: val }));
                      }
                    });
                }

                
            } catch {
              Swal.fire({
                position: "top",
                iconHtml:  '<div class="positoast"></div>',
                title: "System Error :O  Please Login again.",
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                timerProgressBar: true,
                showClass: {
                    popup: 'toastCongratsOn'
                },
                hideClass: {
                    popup: 'toastCongratsOff'
                }
              });
            }
        }
        getData();
    }, []);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log("new link here [" + links.length + "]");
        // first check the name
        let nameIsDup = false;
        if (links.length >= 1) {
          links.forEach((link) => {
            if (link.name === inputs.name) {
              nameIsDup = true;
            }
          })
        }

        const saveData = async () => {
            let browsertabtitle = inputs.browsertabtitle;
            if (browsertabtitle==="") {
              browsertabtitle = inputs.headline;
            }
            try {
                const response = await fetch(process.env.REACT_APP_BE+'/link', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', 
                        Authorization: `Bearer ${getTokenData().accessToken}`},
                    body: JSON.stringify( { account: inputs.account, linkprofile: inputs.ID, name: inputs.name, headline: inputs.headline, instructionaltext: inputs.instructionaltext, submitbuttonlabel: inputs.submitbuttonlabel, browsertabtitle: browsertabtitle } )
                });
                const result = await response.json();

                if (result.status === "success") {
                    console.log("link created");
                    localStorage.setItem("editLinkID", result.data.insertId);
                    navigate("/linkcontent");
                } else {
                  if (result.code === 555) {
                    Swal.fire({
                      position: "top",
                      iconHtml:  '<div class="correctitoast"></div>',
                      title: "You have reached the limit of Learning Links that you can create.",
                      showConfirmButton: false,
                      timer: 2000,
                      toast: true,
                      timerProgressBar: true,
                      showClass: {
                          popup: 'toastWarningOn'
                        },
                      hideClass: {
                          popup: 'toastWarningOff'
                      }
                    });
                    navigate("/xcl/links");
                  }
                }
            } catch {
              Swal.fire({
                position: "top",
                iconHtml:  '<div class="positoast"></div>',
                title: "System Error :O  Please Login again.",
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                timerProgressBar: true,
                showClass: {
                    popup: 'toastCongratsOn'
                },
                hideClass: {
                    popup: 'toastCongratsOff'
                }
              });
            }
        }
        if (!nameIsDup) {
          saveData();
        } else {
          Swal.fire({
            position: "top",
            iconHtml:  '<div class="correctitoast"></div>',
            title: "You already have a link with this name, please chose a unique name.",
            showConfirmButton: false,
            timer: 2000,
            toast: true,
            timerProgressBar: true,
            showClass: {
                popup: 'toastWarningOn'
              },
            hideClass: {
                popup: 'toastWarningOff'
            }
          });
        }
    
    }

    const changeModeToMobile = () => { 
        setLpMode( "mobile" );
    }

    const changeModeToWeb = () => { 
        setLpMode( "web" );
    }

    const doSelectProfile = (event) => {

      const val = event.target.id;
      const ind = profileIDs.indexOf(parseInt(val));
      let pID = 0;
      let pName = "";

      Object.entries(profiles[ind]).forEach(([key, val]) => {
        if (key === "fields") {
          Object.entries(val).forEach(([key, val]) => {
            setFields(values => ({...values, [key]: val }));
          });
        } else {
          const accpetedOverwrites = ['name','headline', 'instructionaltext', 'submitbuttonlabel', 'browsertabtitle'];
          if (!accpetedOverwrites.includes(key)) {
            setInputs(values => ({...values, [key]: val }));
          }
          if (key==="ID") {
            const newKey = "linkprofile";
            setInputs(values => ({...values, [newKey]: val }));
            pID = val;
          }
          if (key==="name") {
            pName = val;
          }
        }

        setCurrentProfile( { name: pName, ID: pID } );
        
        setIsOpen(false);

      });
    }

    const progress = "33%";

    return (
        <>
        <Header/>
        <ScrollToTop/>
        <style>{createStyles}{dropdownstyles}</style>
        <div className='createnew header'>
            <div className='stepperheader'>
            <Link to="/xcl/links">&lt; Back to Dashboard</Link>
                <h3>
                Create a Learning Link 
                </h3>
                <p>
                Create and customize your learning link and easily apply a brand template. 
                </p>
                <div className='steps'>
                  <div className='step links active'>
                      <Link>
                      <div className='indicator' title="step 1">1</div>
                      Name and Brand Details
                      </Link>
                  </div>
                  <hr/>
                  <div className='step upcoming'>
                      <Link>
                      <div className='indicator' title="step 2">2</div>
                      Learning Link Content
                      </Link>
                  </div>
                  <hr/>
                  <div className='step upcoming'>
                      <Link>
                      <div className='indicator' title="step 3">3</div>
                      Publish Link
                      </Link>
                  </div>
                </div>
            </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='formContainer'>
            <a name="contentstarts" className='contentStarts' href='#start'>
              <h4 name="start">Name your Learning Link</h4>
            </a>
            <h5>Give your learning link a name. This name will help you identify it and won't appear on the learning link landing page.</h5>
            <label htmlFor="name">Learning Link Name</label>
              <input type="text"
                value={inputs.name || ""}
                maxLength="45"
                name="name" 
                onChange={handleChange}
                placeholder="e.g. Workplace Safety Introduction Module"></input>
          </div>
          
          
          
          <div className='formContainer'>
            <h4>Apply a brand template to your learning link</h4>
            <h5>Select a brand template and apply it to your learning link landing page. </h5>
            <Link to="/profilenew">Create a new brand template</Link>
            <div className='ddconntainer'>
              <Link className={(isOpen) ? 'ddbutton open': 'ddbutton'} {...buttonProps}>
                {currentProfle.name}
              </Link>
                { (profiles.length > 0) ? (
                <>
                  <div style={{ position: "absolute" }}>
                    <div className={"listMenu " + (isOpen ? 'visible' : '')} role='menu' style={{ top: "8px", left: "0px", minWidth: "456px" }}>
                      { profiles.map((profile, i) => (
                        <Link {...itemProps[0]} key={i} id={profile.ID} onClick={doSelectProfile} className={(currentProfle.ID===profile.ID) ? 'selected': ''}>{profile.name}</Link>
                      )) }          
                    </div>
                  </div>
                  </>
                ) : ( <></> )}
              </div>
            <div className='lpControls' style={{ top: "35px" }}>
                <input type="button" className={(lpMode === "mobile") ? "active" : ""} value="Mobile" onClick={changeModeToMobile}/>
                <input type="button" className={(lpMode === "web") ? "active" : ""} value="Web" onClick={changeModeToWeb}/>
            </div>
            <LivePreview
              topMargin="90px"
              lpMode={lpMode}
              profileFields={profileFields}
              inputs={inputs}
              systemSettings={systemSettings}
            />
        </div>

        <div className='formContainer'>
            <h4>Customize your landing page text</h4>
            <h5>Enter a welcoming headline to let your participants know what your learning link is for.  Add instructional text to let your 
              participants know what they need to do to begin, and the title that will appear in the browser link.</h5>
            <label htmlFor="headline">Headline Text</label>
            <input type="text"
              name="headline" 
              maxLength="60"
              value={inputs.headline || ""}
              onChange={handleChange}
              placeholder="e.g. Welcome to the Workplace Safety Introduction Module"></input>
            <label htmlFor="instructionaltext">Instructional Text</label>
            <input type="text"
              name="instructionaltext" 
              maxLength="150"
              value={inputs.instructionaltext || ""}
              onChange={handleChange}
              placeholder="e.g. Please complete the form to begin"></input>
            <label htmlFor="submitbuttonlabel">Launch Button Text</label>
            <input type="text"
              name="submitbuttonlabel" 
              value={inputs.submitbuttonlabel || ""}
              maxLength="45"
              onChange={handleChange}
              placeholder="e.g. Continue"></input>
            <label htmlFor="browsertabtitle">Browser Tab Title</label>
            <input type="text"
              name="browsertabtitle" 
              maxLength="60"
              value={inputs.browsertabtitle || inputs.headline || ""}
              onChange={handleChange}
              placeholder="e.g. Workplace Safety"></input>
        </div>

        <div className='formFooter'>
            <div className='progressbarbackground'>
                <div className='progressbarprogress links' style={{ width: progress }}></div>
            </div>
            <div className='stepperFormControls'>
                <input type="submit" name='subitBtn' value="Next: Link Content " style={{ backgroundImage: `url('${saveStepIcon}')`}}></input>
            </div>
        </div>
      </form>
      <div className='end'></div>
    </>
  );
};

export default LinkNew;