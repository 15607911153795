import { useState, useEffect } from 'react';
import listStyle from '../xcl_modules/listStyle';
import menuDots from '../css/icons/icon-menudots.png';
import listSortaz from '../css/icons/icon-listsort-az.png';
import listSortza from '../css/icons/icon-listsort-za.png';
import openBox from '../img/openBox.png';
import getTokenData from '../xcl_modules/tokenData';
import { Link, useNavigate } from "react-router-dom";
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import ConfirmDelete from '../components/ConfirmDelete';
import getProfiles from '../xcl_modules/profiles';
import Swal from 'sweetalert2';

const menuDotsAlt = "Open the context menu for this item";
const listSortazAlt = "Column sorted in ascending order, click to change";
const listSortzaAlt = "Column sorted in descending order, click to change";
const openBoxAlt = "Empty box";

const startData = [
    {
        ID: 100,
        item1: "|||",
        item2: "|||",
        item3: "|||",
        item4: "|||",
        item5: "|||",
        item6: "|||"
    },
    {
        ID: 200,
        item1: "|||",
        item2: "|||",
        item3: "|||",
        item4: "|||",
        item5: "|||",
        item6: "|||"
    },
    {
        ID: 300,
        item1: "|||",
        item2: "|||",
        item3: "|||",
        item4: "|||",
        item5: "|||",
        item6: "|||"
    }
]
const startShowkeys = '["item1","item2","item3","item4","item5","item6"]';
// settings
//const itemsPerPage = 6;

const SortableIcon = (props) => {
    const direction = props.dir;

    if (direction === "za") {
        return (
            <img src={listSortza} alt={listSortzaAlt} key={props.icoKey} />
        );
    } else {
        return (
            <img src={listSortaz} alt={listSortazAlt} key={props.icoKey} />
        );
    } 
}


export const List = (props) => {
    const [hookedData, setData] = useState( startData );
    const [showkeys, setShowkeys] = useState( startShowkeys );
    const [cap, setCap] = useState( 1 );
    const [len, setLen] = useState( 0 );
  // map rows
    const tableHeaders = JSON.parse(props.keylabels);
    const sortablekeys = JSON.parse(props.sortablekeys);
    // empty data
    //props.emptyheading;
    //props.emptyinstruction;
    //props.linklabel
    //props.linkto

    // get table from api
    
    useEffect(() => {
        const cachedProfiles = getProfiles();

        const getData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_BE+'/'+props.table, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json', 
                        Authorization: `Bearer ${getTokenData().accessToken}`}
                });
                const result = await response.json();
                setShowkeys(props.showkeys);
                if (result.code === 202) {
                    // there are no templates :(
                    result.list = [ { ID:0, listEmpty: "message" } ];
                }
                setCap(result.profilecap);
                setLen(result.list.length);
                
                if (localStorage.getItem("editProfileID") !== "") {
                    //console.log(cachedProfiles);
                    //console.log("we're editing, ... cached=" + cachedProfiles.length + ", result=" + result.list.length );
                    if (cachedProfiles.length === result.list.length) {
                        // nothing changed, but we have an edit
                        Swal.fire({
                            position: "top",
                            iconHtml:  '<div class="positoast"></div>',
                            title: "Your edits have been saved!",
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            timerProgressBar: true,
                            showClass: {
                                popup: 'toastCongratsOn'
                            },
                            hideClass: {
                                popup: 'toastCongratsOff'
                            }
                        });
                    }
                    if (cachedProfiles.length < result.list.length) {
                        // we have a new 
                        Swal.fire({
                            position: "top",
                            iconHtml:  '<div class="positoast"></div>',
                            title: "Brand Template successfully created!",
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            timerProgressBar: true,
                            showClass: {
                                popup: 'toastCongratsOn'
                            },
                            hideClass: {
                                popup: 'toastCongratsOff'
                            }
                        });
                    }
                    localStorage.setItem("editProfileID", "");
                }
                props.setCapAndLen(result.profilecap, result.list.length);
                setData(result.list);
                localStorage.setItem("xclprofiles", JSON.stringify(result.list));
            } catch (err) {
                Swal.fire({
                    position: "top",
                    iconHtml:  '<div class="positoast"></div>',
                    title: "System Error :O  Please Login again.",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                    timerProgressBar: true,
                    showClass: {
                        popup: 'toastCongratsOn'
                    },
                    hideClass: {
                        popup: 'toastCongratsOff'
                    }
                });
            }
        }
        getData();
    }, [props]);

    let currentShowKeys = JSON.parse(showkeys);

    return (
        <>
            <style>{listStyle}</style>
            <table className='listtable'>
                <tbody>
                    <tr>
                        { tableHeaders.map((header, i) => 
                            <th key={currentShowKeys[i]}>{header}
                            { sortablekeys.includes(currentShowKeys[i]) ? (
                                <SortableIcon dir="az" icoKey={'icon_'+i} />
                            ) : ( <></> ) }
                            </th>
                        ) }
                        <th></th>
                    </tr>
                    { (hookedData[0].listEmpty) ? (
                        <tr>
                            <td colSpan={currentShowKeys.length} className='stophover'>
                                <div className='emptyTable'>
                                    <img src={openBox} alt={openBoxAlt}/>
                                    <h3>{props.emptyheading}</h3>
                                    <p>{props.emptyinstruction}</p>
                                    
                                    {(cap<=len) ? (
                                    <>
                                        You've reach the limit of Brand Templates that you can create
                                    </>          
                                    ) : (
                                    <>
                                        <Link to={props.linkto} >{props.linklabel}</Link>
                                    </>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ) : hookedData.map((rowData, i) =>
                        <Row 
                            key={'row_'+i} 
                            rowKey={rowData.ID+'_'+i} 
                            index={i} 
                            showkeys={showkeys} 
                            data={JSON.stringify(rowData)} 
                            rowID={rowData.ID}
                            doSelectRow={() => props.doSelectRow(rowData.ID)} />
                    )}
                </tbody>
            </table>
        </>
    )
}

const Row = (props) => {
    // map to cols
    const showkeys = JSON.parse(props.showkeys);
    const rowKey = props.rowKey;
    const theData = JSON.parse(props.data);
    const linksUsing = theData.linksusing;

    const [deleteConfirm, setDeleteConfirm] = useState(false);

    const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(3);

    const nav = useNavigate();

    const logoBgcolor = "#f2f5f5";

    const thisData = Object.keys(theData)
    .filter(key => showkeys.includes(key))
    .reduce((obj, key) => {
        obj[key] = theData[key];
        return obj;
    }, {});
    
    const doDuplicate = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BE+'/linkprofileduplicate/'+props.rowID, {
                method: 'GET',
                headers: {'Content-Type': 'application/json', 
                    Authorization: `Bearer ${getTokenData().accessToken}`}
            });
            const result = await response.json();
            
            if (result.status === "success") {
                // it was duplicated
                // show green announce
            } else {
                if (result.code === "333") {
                    // at the max
                    Swal.fire({
                        position: "top",
                        iconHtml:  '<div class="correctitoast"></div>',
                        title: "You have reached the maximum number of profiles and cannot duplicate.",
                        showConfirmButton: false,
                        timer: 2000,
                        toast: true,
                        timerProgressBar: true,
                        showClass: {
                            popup: 'toastWarningOn'
                          },
                        hideClass: {
                            popup: 'toastWarningOff'
                        }
                    });
                }
                // error
                // show red announce
            }
            
        } catch {
            Swal.fire({
                position: "top",
                iconHtml:  '<div class="positoast"></div>',
                title: "System Error :O  Please Login again.",
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                timerProgressBar: true,
                showClass: {
                    popup: 'toastCongratsOn'
                },
                hideClass: {
                    popup: 'toastCongratsOff'
                }
            });
        }
        //console.log("rename row " + props.rowID);
        setIsOpen(false);
        nav("/xcl/profiles");
        
    }
    
    const doDelete = async () => {
        // throw up a popup to verify, then do this
        setIsOpen(false);
        setDeleteConfirm(false);
        
        try {
            const response = await fetch(process.env.REACT_APP_BE+'/linkprofile', {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json', 
                    Authorization: `Bearer ${getTokenData().accessToken}`},
                body: JSON.stringify( { ID: props.rowID } )
            });
            const result = await response.json();
            
            if (result.status === "success") {
                // it was deleted
                // show green announce
            } else {
                // error
                // show red announce
                if (result.code === 202) {
                    Swal.fire({
                        position: "top",
                        iconHtml:  '<div class="correctitoast"></div>',
                        title: "There are XCL Links using this Brand Template, you cannot delete it.",
                        showConfirmButton: false,
                        timer: 2000,
                        toast: true,
                        timerProgressBar: true,
                        showClass: {
                            popup: 'toastWarningOn'
                          },
                        hideClass: {
                            popup: 'toastWarningOff'
                        }
                    });
                }
            }
            
        } catch {
            Swal.fire({
                position: "top",
                iconHtml:  '<div class="positoast"></div>',
                title: "System Error :O  Please Login again.",
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                timerProgressBar: true,
                showClass: {
                    popup: 'toastCongratsOn'
                },
                hideClass: {
                    popup: 'toastCongratsOff'
                }
            });
        }
        //console.log("delete row " + props.rowID);
        nav("/xcl/profiles");
    }

    const doConfirmDelete = () => {
        setDeleteConfirm(true);
    }

    const deleteConfirmClose = () => {
        setDeleteConfirm(false);
    }

    const doEdit = () => {
        localStorage.setItem("editProfileID", props.rowID);
    }
    
    return (
        <tr key={rowKey+'_tr'}>
            { showkeys.map((key, i) =>
                <Cell 
                    key={'cell_'+i} 
                    cellKey={rowKey+"_"+i} 
                    rowID={props.rowID} 
                    data={thisData[key]} 
                    datakey={key} 
                    logoBgcolor={logoBgcolor}
                    doSelectRow={() => props.doSelectRow(props.rowID)} />
            )}
            <td className='listmenu' key={rowKey+'_td2'}>
                <button key={rowKey+'_btn'} {...buttonProps}>
                    <img src={menuDots} alt={menuDotsAlt} key={rowKey+'_img'} />
                    Menu
                </button>
                <div style={{ position: "absolute" }}>
                    <div className={"listMenu " + (isOpen ? 'visible' : '')} role='menu' style={{ right: "-74px" }}>
                        <Link {...itemProps[0]} onClick={doEdit} to="/profilename">Edit</Link>
                        <Link {...itemProps[1]} onClick={doDuplicate}>Duplicate</Link>
                        <Link {...itemProps[2]} onClick={doConfirmDelete}>Delete</Link>
                    </div>
                </div>
                <ConfirmDelete 
                object="Brand Template"
                objectname={thisData.name}
                onConfirm={doDelete}
                open={deleteConfirm} 
                number={linksUsing} 
                onClose={deleteConfirmClose} />
            </td>
        </tr>
    )
}

const Cell = (props) => {

    let data = props.data;
    const datakey = props.datakey;
    const cellKey = props.cellKey;
    
    const doLogoClick = () => {
        localStorage.setItem("editProfileID", props.rowID);
    }
    
    // dates
    if ((datakey.toLowerCase().indexOf("date") > -1 || datakey.toLowerCase().indexOf("created") > -1 || datakey.toLowerCase().indexOf("modified") > -1) && (data !== "|||")) {
        const dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        data = new Date(data).toLocaleDateString('en-US',dateOptions);
    }
    // logos
    if ((datakey.toLowerCase().indexOf("brand_logo") > -1) && (data !== "|||") && (data !== "")) {
        const imgSrc = data;
        const logoBgcolor = props.logoBgcolor;
        data = (
            <Link to="/profilename" className='logolink' style={{ backgroundColor: logoBgcolor, borderRadius: "8px", cursor: "pointer" }} title='Edit this Profile' onClick={doLogoClick} >
                <img src={imgSrc} alt="brand logo" style={{ height:"56px"}} /> 
            </Link>
        )
    }


    if (data === "|||") {
        // start state lazy loading box
        data = (
            <div style={{ backgroundColor: "#EEEEEE", borderRadius: "6px", height: "1em" }}/>
        )
    }

    return (
        <td key={cellKey}>
            { data ? data : `-` }
        </td>
    )
}
