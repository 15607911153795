import ScrollToTop from '../components/ScrollToTop';
import linksStyle from '../xcl_modules/linksStyle';
import dropdownstyles from '../xcl_modules/dropdownstyle';
import { List } from '../components/ListProfiles';
import validate from '../xcl_modules/validate';
import brandHorn from '../css/icons/icon-horn.png';
import brandHornButtonGraphic from '../css/icons/icon-horn-btn.png';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useOutletContext } from "react-router-dom";

const brandHornAlt = "Brand icon";

const Profiles = (props) => {

  const [cap, setCap] = useState( 1 );
  const [len, setLen] = useState( 0 );

  const [navOpen] = useOutletContext();

  const location = useNavigate();

  useEffect(() => {
    validate();
  }, []);

  const doSelectRow = (rowID) => {
    localStorage.setItem("editProfileID", rowID);
    location("/profilename");
  } 

  const doSetCapAndLen = (thisCap, thisLen) => {
    setCap(thisCap);
    setLen(thisLen);
  }

  return (
    <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
      <style>{linksStyle}{dropdownstyles}</style>
      <ScrollToTop/>
      <a name="contentstarts" className='contentStarts' href='#start'>
        <h1 name="start">
          <img src={brandHorn} alt={brandHornAlt} className='iconInline' />
          My Brand Templates
        </h1>
      </a>
      <Link to={((cap<=len) ? '' : '/profilenew')} tabIndex={1} className={'createNewBtn profiles '+((cap<=len) ? 'disabled' : '')} style={{ backgroundImage: `url('${brandHornButtonGraphic}')`}}>     
        {(cap<=len) ? (
          <>
            You've reach the limit of Brand Templates that you can create
          </>          
        ) : (
          <>
            Create a New Brand Template
          </>
        )}
      </Link>
      <h4>
        Create a branded landing page template and apply it to your learning links for easy set up. 
      </h4>
      <List 
        table='linkprofiles' 
        showkeys='["brand_logo","name","created","modified"]' 
        keylabels='["Template Logo","Template Name","Created On","Last Modified"]' 
        sortablekeys='["name"]' 
        emptyheading="You haven't created a branded landing page template yet!"
        emptyinstruction="Create a branded landing page template and apply it to your learning links. By creating a branded template, you'll save time by not having to set up branding every time you create a learning link."
        linklabel="Create a Brand Template"
        linkto="/profilenew"
        setCapAndLen={ doSetCapAndLen }
        doSelectRow={ doSelectRow }
        />
    </div>
  );
};

export default Profiles;